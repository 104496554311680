<template>
    <div class="dict">

        <div class="mainList">
            <div class="mainList_content box-shadow">
                <div class="mainList_content_operation">
                    <div class="mainList_content_operation-l">
                        <el-button size="small" icon="el-icon-plus" v-if="permission(['DICT_ADD'])" @click="add">新增</el-button>
                        <el-button type="danger" icon="el-icon-delete" v-if="permission(['DICT_DEL'])" size="small" @click="del()">批量删除</el-button>
                    </div>
                    <div class="mainList_content_operation-c">
                        <div class="item">
                            <div class="label">字典名称</div>
                            <el-input placeholder="请输入" size="small" clearable v-model="parameter.condition.name" />
                        </div>
                        <el-button class="item" type="primary" size="small" icon="el-icon-search" @click="pageChangeHandler(1)">搜索</el-button>
                    </div>
                </div>
                <div class="mainList_content_sub">
                    <el-table ref="multipleTable"
                              border
                              :data="tableData"
                              tooltip-effect="dark"
                              height="string"
                              :row-style="rowStyle"
                              @row-click="rowClick"
                              v-loading="loading"
                              header-cell-class-name="meterHeader">
                        <el-table-column type="selection" align="center" width="55" fixed="left"></el-table-column>
                        <el-table-column align="center" prop="name" label="字典名称"></el-table-column>
                        <el-table-column prop="code" align="center" label="字典代码"></el-table-column>
                        <el-table-column label="操作" fixed="right"  align="center" width="150"  v-if="permission(['DICT_EDIT','DICT_DEL'])">
                            <template slot-scope="scope">
                                <el-button type="text" class="edit" @click="edit(scope.row)" v-if="permission(['DICT_EDIT'])">编辑</el-button>
                                <el-button type="text" class="del" @click="del(scope)" v-if="permission(['DICT_DEL'])">删除</el-button>
                            </template>
                        </el-table-column>
                    </el-table>
                    <el-pagination
                        @current-change="pageChangeHandler"
                        @size-change="handleSizeChange"
                        :current-page="parameter.pageNumber"
                        :page-size="parameter.pageSize"
                        prev-text="上一页"
                        next-text="下一页"
                        :total="parameter.total">
                    </el-pagination>
                </div>
            </div>
        </div>
        <Sub :data="activeData" />

        <Edit :isShow.sync="showEditDialog" :options="options" :refresh="refresh"></Edit>
    </div>
</template>
<script>
import mixinsList from '@/mixins/list'
export default {
    components: {
        Edit: () => import("./Edit.vue"),
        Sub: () => import("./Sub.vue"),
    },
    mixins: [mixinsList],
    data() {
        return {
            api:{
                findPage:this.$api['admin/dictClassify'].findPage,
                del:this.$api['admin/dictClassify'].del,
            },
            activeData:{},
            activeId:null,
        };
    },
    created() {
        this.getData();
    },
    methods: {
        rowClick(row){
            this.activeData = row;
        },
        rowStyle(res){
            if(res.row.code==this.activeData.code){
                return {
                    background:'#f5f7fa'
                }
            }else {
                return {}
            }
        },
    },
};
</script>
<style scoped lang='scss'>
    @import "@/styles/config.scss";
    .dict {
        display:flex;
        .mainList {
            width:calc(50% - 10px);
            padding-right:10px;
            +.mainList {
                padding-left:10px;
            }
        }
    }
</style>
